import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';

class UrlMap {
  public old: string = '';
  public new: string = '';
}

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  public updateMessage: string = '';
  private allUrls: any = [];

  constructor(private settings: SettingsService) { 
    this.settings.log(0,'UpdateService constructor');
  }
    pageIsReady(staticPageUrl, pageId='', timing='current', extraid='') {
      let t = this;
      let s = t.settings;
      s.log(1,'pageIsReady staticPageUrl=' + staticPageUrl);
      // GR 20210504 function updated to return page data for authenticated user of private app or empty object otherwise
      return new Promise<any>((resolve, reject) => {  	
        let empty = {"numbers":{},"quoteRequests":[],"quotes":[],"orders":[]};
        s.platform.ready().then(() => {
          s.log(1,'pageIsReady platform.ready staticPageUrl=' + staticPageUrl);
          s.getCoreModulesData().then(() => {
            s.log(1,'pageIsReady s.appType=' + s.appType + ' staticPageUrl=' + staticPageUrl + ' s.passwordAuthenticationInBrowserEnabled=' + s.passwordAuthenticationInBrowserEnabled + ' s.passwordAuthentication=' + s.passwordAuthentication);
            if (staticPageUrl != 'appcomponent' && staticPageUrl !=  'SplashPage') {
              s.configureMatomoAndFirebase();
            }
            // Private - deviceid is coming from the server when authorised and stored in secure storage getSecureKey('appkeys')
            // Public - deviceid is generated on device and stored in storage getKey('deviceid')
            if (s.appType == 'public') {
              s.readCurrentAppJson().then((currentData: any)=>{
                s.getDeviceId().then((deviceid: any)=>{
                  s.getNotifications().then(()=>{
                    t.checkAppUpdatesInBackground();	
                    s.log(1,'pageIsReady true public');
                    if (staticPageUrl == 'LoginPage') s.openPage('HomePage');  
                    resolve(empty);
                  }).catch((error)=>{
                    s.log(1,'pageIsReady 1 can not read notifications: ' + error.message);
                    resolve(empty);
                  });
                });
              }).catch((error)=>{
                s.currentAppData = {};
                s.log(1,'pageIsReady 1 error getting readCurrentAppJson: ' + error.message);
                reject(error);
              });			
            } else {
              s.userAuthorised().then((authorised:boolean)=>{
                s.log(1,'pageIsReady authorised=' + authorised + ' userAuthenticated=' + s.userAuthenticated);
                if (authorised) {
                  if (s.userAuthenticated) {
                    if (s.isConnected() && s.trackMatomoAnalytics && staticPageUrl != 'appcomponent' && staticPageUrl !=  'SplashPage') {   
                      s.matomoTracker.setCustomVariable(5,"role",s.currentUserRole,"page");  
                      s.matomoTracker.setUserId(s.currentUserData.Username + '_' + s.currentUserData.Userid);                
                    }                     
                    s.readCurrentAppJson().then((currentData: any)=>{
                      s.getNotifications().then(()=>{
                        t.checkAppUpdatesInBackground();
                        let pageName = staticPageUrl;
                        if (pageName && pageName.endsWith('Page')) { 
                          pageName = pageName.substring(0,pageName.length-4).replace('/','').replace(/\-/g,'');
                        } 
                        if (!extraid || extraid == 'null') {
                          extraid = '';
                        }                       
                        s.log(1,'pageIsReady true private extraid=' + extraid);
                        //if (!extraid) {
                        //  extraid = s.currentUserData.Userid;
                        //}
                        //GetPageData				    (page, utype, cid, capmakes)
                        //GetPageDataFromServer	(page, utype, cid, id, 'current', capmakes)                        
                        this.settings.executeCustomCommand('GetPageDataFromServer','page=' + pageName + 
                            '&utype=' + s.currentUserRole + '&id=' + pageId + '&timing=' + timing + 
                            '&capMakes=' + encodeURIComponent(s.currentUserData.capMakes) + 
                            '&capconditions=' + encodeURIComponent(s.currentUserData.capConditions) + 
                            '&extraid=' + extraid ).then((result)=>{
                          this.settings.log(1,'*** "GetPageDataFromServer" returns: ' + JSON.stringify(result));
                          resolve(result);
                        }).catch(error=>{
                          reject(error);
                          this.settings.log(2,'*** error returned from GetPageDataFromServer" call error: ' + JSON.stringify(error));
                        }); 
                      }).catch((error)=>{
                        s.log(1,'pageIsReady 2 can not read notifications: ' + error.message);
                        resolve(empty);
                      });
                    }).catch((error)=>{
                      s.currentAppData = {};
                      s.log(1,'pageIsReady 2 error getting readCurrentAppJson: ' + error.message);
                      reject(error);
                    });		
                  } else {
                    // extra check here for the case when browser is refreshed
                    this.settings.checkBrowserAuthentication(this).then((result)=>{
                      if (!result && staticPageUrl != 'TermsAndConditionsPage') {
                        if (staticPageUrl != 'LoginPage') s.openPage('LoginPage');
                      }
                      resolve(empty);
                    });
                  }
                } else {
                  if (staticPageUrl != 'TermsAndConditionsPage' && staticPageUrl != 'SplashPage') {
                    let page = 'LoginPage';
                    if (s.lastActivationKeyReceivedDate && s.timeDiffInMinutes(Date.now(),s.lastActivationKeyReceivedDate) < 61) {
                      if (staticPageUrl != 'AuthoriseKeyPage') page = 'AuthoriseKeyPage';
                    } else {
                      if (staticPageUrl != 'AuthoriseEmailPage') page = 'AuthoriseEmailPage';
                    }	
                    s.log(1,'pageIsReady: not authorised staticPageUrl=' + staticPageUrl + ' about to open page: ' + page);
                    s.openPage(page);
                  }						
                  resolve(empty);
                }
              });
            }
          }).catch((error) => {
            s.log(1,"Error catched in getCoreModulesData: " + error.message);
            reject(error);
          });
        }).catch((error) => {
          reject(error);
        })
      })
    }
  
    checkAppUpdatesInBackground() {
      let timeDiff = null;
      let currentTime = new Date();    
      if (this.settings.lastUpdateChecked != null) {
        timeDiff = this.settings.timeDiffInMinutes(currentTime, this.settings.lastUpdateChecked);
        this.settings.log(0,'checkAppUpdatesInBackground: timeDiff=' + timeDiff + ' checkForUpdatesTimeInMinutes=' + this.settings.checkForUpdatesTimeInMinutes);
      }
      let wifiConnected = this.settings.isWifiConnected();
      let isConnected = this.settings.isConnected();
      this.settings.log(1,'checkAppUpdatesInBackground: isWifiConnected=' + wifiConnected + ' isConnected=' + isConnected + ' timeDiff=' + timeDiff + ' checkForUpdatesTimeInMinutes=' + this.settings.checkForUpdatesTimeInMinutes + ' updateIsRunning=' + this.settings.updateIsRunning);
      // run auto updates only on WiFi ! - nop change to general connected check after talk to Rob
      if (isConnected && (timeDiff == null || timeDiff > this.settings.checkForUpdatesTimeInMinutes) && !this.settings.updateIsRunning) {
        // get app notifications - time check is to avoid running check twice when push link is clicked
        this.settings.log(1,'checkAppUpdatesInBackground: lastNotificationChecked=' + this.settings.lastNotificationChecked);
        if (this.settings.lastNotificationChecked == null || this.settings.timeDiffInMinutes(currentTime,this.settings.lastNotificationChecked) > 10) {
          this.settings.checkServerForNotifications();
        }
        // start background update process
        this.appUpdate(this.settings.sourceHost).then((result)=>{
          this.settings.log(1,'checkAppUpdatesInBackground: result: App updates successfully finished!');
          this.updateMessage = 'App updates successfully finished!';
          this.settings.trackMatomoEvent("Management", "Update", "App Update Content (Automatic)", 1);
          this.settings.lastUpdateChecked = new Date();
        }).catch((error)=>{
          this.settings.trackMatomoEvent("Management", "Update", "App Update Content (Automatic)", 0);
          this.settings.log(2,'checkAppUpdatesInBackground: result: Error ' + error);
          this.updateMessage = error;
          // should we update lastUpdateChecked after errors too? May be do it for now.
          this.settings.lastUpdateChecked = new Date();
        })
      }    
    }
  
    appUpdate(sourceHost, getAllContent = false) {
      var t = this;
      t.settings.updateIsRunning = true;    
      t.allUrls = [];
      //this.settings.logDisplayLevel = 0;
      var currentJsonData = null;
      return new Promise<string>((resolve, reject) => {
        // GR. We temporary use startappupdate below to allow access old style function
        let params = 'nt=' + t.settings.currentUserData.SubsribedToFirebaseTopics.join() + 
        '&dp=' + this.settings.platform.platforms() + '&dt=' + this.settings.deviceToken + 
        '&appv=' + t.settings.currentUserData.ActiveAppVersion;
        if (getAllContent || t.settings.currentUserData.appVersionChanged) {
          params += '&ac=1'; 
        }
        t.settings.executeCommand('startappupdate', params).subscribe((result: any)=>{
          if (!result) {
            // it seems that we ocassionally getting empty result from the server. Is it timeout?
            result.Success = false;
            result.Message = "Empty resposnse from the server";
          }
          t.settings.log(1,"appUpdate startappupdate appVersionChanged=" + t.settings.currentUserData.appVersionChanged + " result.Success=" + result.Success);
          if (result.Success) {
            //let patch = result.Data["patch"];
            let patch = result.patch;
            if (patch) {
              t.settings.log(1,"appUpdate patch.length=" + patch.length); 
            }
            let moduleslastmodified = {};
            let updateSuccess = true;
            let updateMessage = "error during update";
            let blnNameChanged = false;
            if (patch && patch.length > 0) {
              // get current JSON from the file. Do we want to store whole thing in one file?
              t.settings.readCurrentAppJson().then((currentData)=>{
                currentJsonData = currentData;
                t.settings.log(1,"after readCurrentAppJson: getAllContent=" + getAllContent);
                if (getAllContent) {
                  // we want to remove all pages and reset them
                  currentJsonData.pages = {};
                }              
  
                let updatePromises = [];
                for (let i=0; i<patch.length; i++) {
                  if (patch[i]) {
                    if ((patch[i].op == "add" || patch[i].op == "replace") && patch[i].value) {
                      // add or modify item: path, value
                      updatePromises.push(t.processUpdateFields(sourceHost, patch[i].value).then((newvalue:any)=>{
                        t.settings.log(0,"processUpdateFields " + patch[i].op + " path=" + patch[i].path + " newvalue=" + t.settings.abbreviate(JSON.stringify(newvalue),50));
                        let pp = t.splitPath(patch[i].path);
                        // set data to the current JSON
                        let currentParent = t.settings.getJsonPropertyByPath(currentJsonData, pp.pathParent); 
                        if (typeof currentParent == 'undefined' || currentParent == null) {
                          currentParent = {};
                        }
                        t.settings.log(0,"processUpdateFields after splitPath pp.pathParent=" + pp.pathParent + " pp.pathLeaf=" + pp.pathLeaf + ' currentParent=' + currentParent);
   
                        if ((pp.pathParent == '' && pp.pathLeaf == 'userfirstname') || 
                            (pp.pathParent == 'userfirstname' && pp.pathLeaf == '')) {
                          t.settings.log(0,"=== appUpdate CURRENT userfirstname=" + t.settings.currentUserData.Firstname + " newvalue=" + newvalue);
                          if (t.settings.currentUserData.Firstname != newvalue) {
                              t.settings.log(1,"*** appUpdate userfirstname updated to " + newvalue);
                              t.settings.currentUserData.Firstname = newvalue;
                              blnNameChanged = true;
                          }
                        }                      
                        if ((pp.pathParent == '' && pp.pathLeaf == 'userlastname') || 
                            (pp.pathParent == 'userlastname' && pp.pathLeaf == '')) {
                          t.settings.log(0,"=== appUpdate CURRENT userlastname=" + t.settings.currentUserData.Lastname + " newvalue=" + newvalue);
                          if (t.settings.currentUserData.Lastname != newvalue) {
                              t.settings.log(0,"*** appUpdate userlastname updated to " + newvalue);
                              t.settings.currentUserData.Lastname = newvalue;
                              blnNameChanged = true;
                          }
                        }
                        // GR added 2018-06-20
                        if ((pp.pathParent == '' && pp.pathLeaf == 'username') || 
                            (pp.pathParent == 'username' && pp.pathLeaf == '')) {
                          t.settings.log(0,"=== appUpdate CURRENT username=" + t.settings.currentUserData.Username + " newvalue=" + newvalue);
                          if (t.settings.currentUserData.Username != newvalue) {
                              t.settings.log(0,"*** appUpdate username updated to " + newvalue);
                              t.settings.currentUserData.Username = newvalue;
                              blnNameChanged = true;
                          }
                        }
                        if ((pp.pathParent == '' && pp.pathLeaf == 'userid') || 
                            (pp.pathParent == 'userid' && pp.pathLeaf == '')) {
                          t.settings.log(0,"=== appUpdate CURRENT userid=" + t.settings.currentUserData.Userid + " newvalue=" + newvalue);
                          if (t.settings.currentUserData.Userid != newvalue) {
                              t.settings.log(0,"*** appUpdate userid updated to " + newvalue);
                              t.settings.currentUserData.Userid = newvalue;
                              blnNameChanged = true;
                          }
                        }             
                        if (pp.pathParent.indexOf('moduleslastmodified') == 0) {
                          t.settings.log(0,"*** appUpdate moduleslastmodified pathLeaf=" + pp.pathLeaf + " newvalue=" + newvalue);
                          if (pp.pathLeaf) {
                            moduleslastmodified[pp.pathLeaf] = newvalue;
                          } else {
                            moduleslastmodified = newvalue;
                          }
                        }                                
                        if (pp.pathLeaf) {
                          if (pp.pathParent.indexOf('moduleslastmodified') != 0) {                     
                            t.settings.log(0,"processUpdateFields currentParent=" + currentParent);                      
                            t.settings.log(1,"processUpdateFields moduleslastmodified currentParent[pp.pathLeaf]=" + currentParent['' + pp.pathLeaf]); 
                            currentParent['' + pp.pathLeaf] = newvalue;
                          }
                        } else {
                          t.settings.log(0,"processUpdateFields pathParent=" + pp.pathParent);
                          currentJsonData[pp.pathParent] = newvalue;
                          //t.settings.log(0,"processUpdateFields 2 SET currentJsonData for " + pp.pathParent + " to: " + t.settings.abbreviate(JSON.stringify(newvalue),50));
                        }
                        t.settings.log(0,"processUpdateFields " + patch[i].op + " i=" + i + " FINISH APPLYING");
                      }).catch((error)=>{
                        updateSuccess = false;
                        t.settings.log(2,"error in updates for patch i=" + i + " " + JSON.stringify(error, Object.getOwnPropertyNames(error)));
                        updateMessage = "error in updates for patch i=" + i + " " + error.message;
                      }));									
                    } else if(patch[i].op == "remove") {
                      // delete existing item: path
                      t.settings.log(1,"processUpdateFields " + patch[i].op + " path=" + patch[i].path);
                      let pp = t.splitPath(patch[i].path);
                      // delete data from the current JSON
                      let currentParent = t.settings.getJsonPropertyByPath(currentJsonData, pp.pathParent); 
                      if (currentParent && pp.pathLeaf) {
                        t.deleteDataItem(currentParent, pp.pathLeaf);			
                      }				
                    } else if(patch[i].op == "move") {
                      // move existing item: path, from. Can we have that at all?
                    } else if(patch[i].op == "copy") {
                      // copy existing item: path, from. We shouldn't have that
                    }
                  }
                }
                t.settings.log(1,"---appUpdate updatePromises.length=" + updatePromises.length + " updateSuccess=" + updateSuccess);
                if (updatePromises.length == 0) {
                  // success
                  //let ppath = "/pages/app_news";
                  //t.settings.log(1 ,"*** appUpdate updates success 1 app_news=" + t.settings.abbreviate(t.settings.getJsonPropertyByPath(currentJsonData, ppath),50));
                  t.settings.writeCurrentAppJson(currentJsonData);
                  t.settings.executeCommand('finishappupdate','status=success').subscribe((result: any)=>{
                    // ***** That's a success *****
                    t.settings.log(1,'finishappupdate done 1');
                    t.settings.updateIsRunning = false; 
                    //t.settings.events.publishData({updatefinished: true});
                    // check and possibly update modules
                    t.settings.updateModulesFromServer(moduleslastmodified);
                    resolve('success');                
                  },function(error) {
                    t.settings.log(2,"Error running finishappupdate 1 " + JSON.stringify(error));
                    t.settings.updateIsRunning = false; 
                    //t.settings.events.publishData({updatefinished: false});
                    reject('Error running finishappupdate ' + JSON.stringify(error));                 
                  })                
                } else {
                  Promise.all(updatePromises).then(() => {
                    t.settings.log(0,"*** updateSuccess=" + updateSuccess + " updateMessage=" + updateMessage);
                    if (updateSuccess) {
                      //success
                      t.settings.writeCurrentAppJson(currentJsonData);
                        // GR - do version related things later
                        let oldversion = t.settings.currentUserData.ActiveAppVersion;
                        t.settings.getAppVersion().then((version:string)=>{
                          t.settings.log(1,"appUpdate blnNameChanged=" + blnNameChanged + " AppVersion=" + version + " old ActiveAppVersion=" + t.settings.currentUserData.ActiveAppVersion);
                          if (blnNameChanged || t.settings.currentUserDataChanged || version != oldversion || t.settings.currentUserData.appVersionChanged != (version != oldversion)) {
                            t.settings.currentUserData.appVersionChanged = (version != oldversion);
                            t.settings.log(1,"appUpdate saving currentUserData appVersionChanged=" + t.settings.currentUserData.appVersionChanged); 
                            t.settings.setSecureKey('userdata', JSON.stringify(t.settings.currentUserData));
                            t.settings.currentUserDataChanged = false;
                          }
                        }).catch((e) => {
                          t.settings.log(2,"*** appUpdate getAppVersion error e=" + e);
                        })                    
                      t.settings.executeCommand('finishappupdate','status=success').subscribe((result: any)=>{
                        // ***** That's a success *****
                        t.settings.log(1,'finishappupdate done 2');
                        t.settings.updateIsRunning = false; 
                        //t.settings.events.publishData({updatefinished: true}); 
                        // check and possibly update modules
                        t.settings.updateModulesFromServer(moduleslastmodified);                                           
                        resolve('success');                
                      },function(error) {
                        t.settings.log(2,"Error running finishappupdate 2 " + JSON.stringify(error));
                        t.settings.updateIsRunning = false; 
                        //t.settings.events.publishData({updatefinished: false});                      
                        reject('Error running finishappupdate ' + JSON.stringify(error));                 
                      })             
                    } else {
                      //error. Can we be here ?
                      t.settings.log(0,"*** appUpdate updates error");
                      t.settings.executeCommand('finishappupdate','status=failure&message=' + 
                        encodeURIComponent(updateMessage)).subscribe((result: any)=>{
                          t.settings.log(1,'finishappupdate after error done');
                          t.settings.updateIsRunning = false; 
                          //t.settings.events.publishData({updatefinished: false});                        
                          reject('appUpdate updates error');                       
                      },function(error) {
                        t.settings.log(2,'Error running finishappupdate 3 ' + JSON.stringify(error));
                        t.settings.updateIsRunning = false; 
                        //t.settings.events.publishData({updatefinished: false});                      
                        reject('Error running finishappupdate ' + JSON.stringify(error));                   
                      });                     
                    }
                  }).catch((e) => {
                    // handle errors here
                    t.settings.log(1,"*** appUpdate error 3: " + JSON.stringify(e));
                    updateMessage = JSON.stringify("appUpdate error 3");
                    t.settings.executeCommand('finishappupdate','status=failure&message=' + 
                      encodeURIComponent(updateMessage)).subscribe((result: any)=>{
                        t.settings.log(1,'finishappupdate done 3');
                        t.settings.updateIsRunning = false; 
                        //t.settings.events.publishData({updatefinished: false});                      
                        reject('appUpdate error 3: ' + JSON.stringify(e));                     
                    },function(error) {
                      t.settings.log(2,'Error running finishappupdate 4 ' + JSON.stringify(error));
                      t.settings.updateIsRunning = false; 
                      //t.settings.events.publishData({updatefinished: false});                    
                      reject('Error running finishappupdate ' + JSON.stringify(error));                  
                    });                 
                  });   
                }          
              }).catch((error)=>{
                t.settings.log(0,"Error reading current App Json data " + JSON.stringify(error));
                updateMessage = JSON.stringify("Error reading current App Json data");
                t.settings.executeCommand('finishappupdate','status=failure&message=' + 
                  encodeURIComponent(updateMessage)).subscribe((result: any)=>{
                    t.settings.log(1,'finishappupdate after update 2 done');
                    t.settings.updateIsRunning = false; 
                    //t.settings.events.publishData({updatefinished: false});                  
                    reject("Error reading current App Json data " + JSON.stringify(error));                 
                },function(e) {
                  t.settings.log(2,'Error running finishappupdate 2 ' + JSON.stringify(e));
                  t.settings.updateIsRunning = false; 
                  //t.settings.events.publishData({updatefinished: false});                
                  reject('Error running finishappupdate ' + JSON.stringify(e)); 
                });            
              });
            } else {
              t.settings.log(0,"*** appUpdate updates success  -- NOTHING TO UPDATE");
              t.settings.executeCommand('finishappupdate','status=success').subscribe((result: any)=>{
                // ***** That's a success *****
                t.settings.log(1,'finishappupdate (NOTHING TO UPDATE) done');
                t.settings.updateIsRunning = false; 
                //t.settings.events.publishData({updatefinished: false});              
                resolve('success');                
              },function(error) {
                t.settings.log(2,"Error running finishappupdate " + JSON.stringify(error));
                t.settings.updateIsRunning = false; 
                //t.settings.events.publishData({updatefinished: false});              
                reject('Error running finishappupdate ' + JSON.stringify(error));                 
              })            
            }
          } else {
            t.settings.log(2,"Error getting updates from the server 1 " + result.Message + " params=" + params);
            t.settings.updateIsRunning = false; 
            //t.settings.events.publishData({updatefinished: false});          
            reject(result.Message);
          }        
        },function(error) {
          t.settings.log(2,"Error getting updates from the server 2 " + JSON.stringify(error) + " params=" + params);
          t.settings.updateIsRunning = false; 
          //t.settings.events.publishData({updatefinished: false});        
          reject(JSON.stringify("Error getting updates from the server 2 " + JSON.stringify(error)));      
        })
      });
    }
  
  
    deleteDataItem(currentParent, pathLeaf) {
      // we should remove related files too - do it later
      this.settings.log(1,"deleteDataItem currentParent=" + currentParent + " pathLeaf=" + pathLeaf + " el=" + currentParent[pathLeaf]);
      if (currentParent[pathLeaf]) {
        delete currentParent[pathLeaf];
      }
    }
    
    splitPath(path) {
      var pp: any = {'pathParent':'','pathLeaf':''};
      if (path) {
        if (path.substr(0,1) == '/') {
          path = path.substr(1);
        }      
        var indx = path.lastIndexOf('/');
        if (indx == -1) {
          pp.pathParent = path;
        } else {
          pp.pathParent = path.substring(0, indx);
          pp.pathLeaf = path.substring(indx + 1);
        }
        this.settings.log(0,"splitPath indx=" + indx + " path=" + path + " pathParent=" + pp.pathParent + " pathLeaf=" + pp.pathLeaf);
      }
      return pp;
    }
  
    // process JSON object or string value in order to extract images, upload them and change 
    // references to point to local image urls
    processUpdateFields(sourceHost, updateItem) {
      var t = this;
      t.settings.log(0,"*** processUpdateFields start");
      if (updateItem.parentid) {
        // this is item object
        var urlPromises = [];
        t.settings.log(0,"processUpdateFields processing summary");
        urlPromises.push(this.getLocalHtml(sourceHost, updateItem.summary).then((newhtml)=>{
          updateItem.summary = newhtml;
        }));
        if (updateItem.body) {
          t.settings.log(0,"processUpdateFields processing body");
          urlPromises.push(this.getLocalHtml(sourceHost, updateItem.body).then((newhtml)=>{
            updateItem.body = newhtml;
          }));
        }
        t.settings.log(0,"processUpdateFields processing thumbnail=" + updateItem.thumbnail);
        if (updateItem.thumbnail) {
          urlPromises.push(this.getLocalHtml(sourceHost, updateItem.thumbnail).then((newhtml)=>{
            t.settings.log(0,"processUpdateFields new thumbnail=" + newhtml);
            updateItem.thumbnail = newhtml;
          }));
        }
        t.settings.log(0,"processUpdateFields processing videotitle=" + updateItem.videotitle);
        if (updateItem.videotitle) {
          urlPromises.push(this.getLocalHtml(sourceHost, updateItem.videotitle).then((newhtml)=>{
            t.settings.log(0,"processUpdateFields new videotitle=" + newhtml);
            updateItem.videotitle = newhtml;
          }));
        }
        t.settings.log(0,"processUpdateFields processing videosummary=" + updateItem.videosummary);
        if (updateItem.videosummary) {
          urlPromises.push(this.getLocalHtml(sourceHost, updateItem.videosummary).then((newhtml)=>{
            t.settings.log(0,"processUpdateFields new videosummary=" + newhtml);
            updateItem.videosummary = newhtml;
          }));
        }    
        t.settings.log(0,"processUpdateFields processing videothumbnail=" + updateItem.videothumbnail);
        if (updateItem.videothumbnail) {
          urlPromises.push(this.getLocalHtml(sourceHost, updateItem.videothumbnail).then((newhtml)=>{
            t.settings.log(0,"processUpdateFields new videothumbnail=" + newhtml);
            updateItem.videothumbnail = newhtml;
          }));
        }    
  
   
        return Promise.all(urlPromises).then(() => {
          this.settings.log(0,"*** processUpdateFields finish");
          return updateItem;
        }).catch((error) => {
          // handle errors here
          this.settings.log(2,"*** processUpdateFields error 1: " + JSON.stringify(error));        
        });     
      } else if (updateItem.attachments) {
        this.settings.log(1,"*** processUpdateFields processing attachments");
        return new Promise((resolve) => {
          resolve(updateItem);
        });	        
      } else {
        // this is string item e.g. updated body
        this.settings.log(0,"*** processUpdateFields processing string element updateItem=" + updateItem + " typeof=" + typeof(updateItem));
        if (typeof(updateItem) == 'string') {
          return this.getLocalHtml(sourceHost, updateItem).then((newhtml)=>{
            updateItem = newhtml;
            return updateItem;
          }).catch((error) => {
            // handle errors here
            this.settings.log(2,"*** processUpdateFields error 2: " + JSON.stringify(error));
          });   
        }  else {
          return new Promise((resolve) => {
            resolve(updateItem);
          });	        
        }
      }
    }
  
    // process html from sourceHost in order to extract images, upload them and change 
    // references in resulting html to point to local image urls
    getLocalHtml(sourceHost, html) {
      //this.settings.log(0,"getLocalHtml start sourceHost=" + sourceHost);
      var urls = this.GetImgUrlsFromHtml(html);
      //this.settings.log(0,"getLocalHtml urls.length=" + urls.length + " html=" + html);
    
      let t = this;
      if (urls && urls.length > 0) {
        var urlMap = [];
        var urlPromises = [];
        for (var i=0; i<urls.length; i++) {
          urlPromises.push(t.settings.getExternalImage(sourceHost,urls[i]).then((result: UrlMap) => {
            t.settings.log(0,"Images i=" + i + " old=" + result.old + " new=" + result.new);
            urlMap.push(result);
          }));
        }
    
        return Promise.all(urlPromises).then(() => {
          // we should have all map data defined here
          var newHtml = t.UpdateImageReferences(html,urlMap);
          t.settings.log(0,"*** getLocalHtml 1 newHtml=" + newHtml);
          newHtml = t.UpdateLocalLinks(sourceHost, newHtml);
          t.settings.log(0,"*** getLocalHtml 2 newHtml=" + newHtml);
          return newHtml;
        }).catch((e) => {
          // handle errors here
          t.settings.log(2,"*** getLocalHtml error: " + e);
        });
        
      } else {
        return new Promise((resolve) => {
          var newHtml = this.UpdateLocalLinks(sourceHost, html);
          resolve(newHtml);
        });		
      }
    }
  
    onErrorCreateFile (e) {
      this.settings.log(2,"Failed to create file: " + JSON.stringify(e));
    }
  
    saveFile(dirEntry, fileData, fileName, t) {
      this.settings.log(0,"saveFile saving file " + fileName);
      dirEntry.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {
          t.writeFile(fileEntry, fileData);
      }, t.onErrorCreateFile);
    }
  
    writeFile(fileEntry, dataObj, isAppend) {
      fileEntry.createWriter(function (fileWriter) {
        fileWriter.onwriteend = function() {
          this.settings.log(0,"writeFile Successful file write...");
        }      
        fileWriter.onerror = function(e) {
            this.settings.log(2,"writeFile Failed file write: " + e.toString());
        };
        fileWriter.write(dataObj);
      });
  }
  
    GetImgUrlsFromHtml(html) {
      var currentHost = window.location.protocol + '//' + window.location.hostname;
      if (window.location.port && window.location.port != '80' && window.location.port != '443') {
        currentHost += ':' + window.location.port;
      }
      var urls = [];
      //if (html && (html.substr(0,1) == '/' || html.substr(0,4) == 'http') && 
      // GR 20180411 - we do NOT want to get full urls !
      if (html && (html.substr(0,1) == '/') && 
          html.indexOf('"') == -1 && html.indexOf('<') == -1 && html.substr(-1).match(/[a-z]/i)) {
        // we consider this as "image" or "thumbnal" field where whole html is an URL
        urls.push(html);
      } else {
        var el = document.createElement('div');
        el.innerHTML = html;
        var tags = el.getElementsByTagName('img');
        //this.settings.log(0,"GetImgUrlsFromHtml: tags.length=" + tags.length);
        if (tags) {
          for (var i=0; i<tags.length; i++) {
            let imageUrl = tags[i].src.replace(currentHost,'');
            if (urls.indexOf(imageUrl) == -1 && imageUrl.startsWith('/')) {
              this.settings.log(0,"GetImgUrlsFromHtml: i=" + i + " imageUrl=" + imageUrl + " currentHost=" + currentHost);
              urls.push(imageUrl);
            }
          }
        }
      }
      this.settings.log(0,"GetImgUrlsFromHtml: urls.length=" + urls.length);
      return urls;
    }
    
    UpdateImageReferences(html, urlMap) {
      var newHtml = html;
      if (urlMap && urlMap.length > 0) {
        this.settings.log(0,"UpdateImageReferences: urlMap.length=" + urlMap.length);
        for (var i=0; i<urlMap.length; i++) {
          var entry=urlMap[i];
          this.settings.log(0,"UpdateImageReferences: entry.old=" + entry.old + " entry.new=" + entry.new);
          // we probably should remove broken image tags
          //newHtml = newHtml.replace(entry.old,entry.new);
          // replace only works for first occurence of string!
          newHtml = newHtml.split(entry.old).join(entry.new);
        }
      }
      return newHtml;
    }  
  
    // add full host references to the links with relative urls like href="/my/path"
    UpdateLocalLinks(sourceHost, html) {
      this.settings.log(0,"UpdateLocalLinks: sourceHost=" + sourceHost + " appSiteHost=" + this.settings.appSiteHost);
      let newHtml = html.replace(/ href="(\/[^"]+)"/gi,' href="' + this.settings.appSiteHost + '$1"');
      // GR 2018-06-21 add jwt to "base site" links. That does not work because given JWT can be used once only and expired too (:
      // we have to dynamically add jwt somehow
      return newHtml;
    }

}
