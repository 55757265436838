import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'dynamic/:id',
    loadChildren: () => import('./pages/dynamic/dynamic.module').then( m => m.DynamicPageModule)
  },
  {
    path: 'authorise-add-pin',
    loadChildren: () => import('./pages/authorise-add-pin/authorise-add-pin.module').then( m => m.AuthoriseAddPinPageModule)
  },
  {
    path: 'authorise-email',
    loadChildren: () => import('./pages/authorise-email/authorise-email.module').then( m => m.AuthoriseEmailPageModule)
  },
  {
    path: 'authorise-key',
    loadChildren: () => import('./pages/authorise-key/authorise-key.module').then( m => m.AuthoriseKeyPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'settings-troubleshooting',
    loadChildren: () => import('./pages/settings-troubleshooting/settings-troubleshooting.module').then( m => m.SettingsTroubleshootingPageModule)
  },
  {
    path: 'notifications-inbox',
    loadChildren: () => import('./pages/notifications-inbox/notifications-inbox.module').then( m => m.NotificationsInboxPageModule)
  },
  {
    path: 'notifications-archive',
    loadChildren: () => import('./pages/notifications-archive/notifications-archive.module').then( m => m.NotificationsArchivePageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'notification-modal',
    loadChildren: () => import('./pages/notification-modal/notification-modal.module').then( m => m.NotificationModalPageModule)
  },
  {
    path: 'content-update',
    loadChildren: () => import('./pages/content-update/content-update.module').then( m => m.ContentUpdatePageModule)
  },
  {
    path: 'quote-request-add',
    loadChildren: () => import('./pages/quote-request-add/quote-request-add.module').then( m => m.QuoteRequestAddPageModule)
  },
  {
    path: 'quote-requests/:timing',
    loadChildren: () => import('./pages/quote-requests/quote-requests.module').then( m => m.QuoteRequestsPageModule)
  },
  {
    path: 'quote-request/:id',
    loadChildren: () => import('./pages/quote-request/quote-request.module').then( m => m.QuoteRequestPageModule)
  },
  {
    path: 'quotes/:timing',
    loadChildren: () => import('./pages/quotes/quotes.module').then( m => m.QuotesPageModule)
  },
  {
    path: 'quote/:id',
    loadChildren: () => import('./pages/quote/quote.module').then( m => m.QuotePageModule)
  },  
  {
    path: 'quote-add/:id',
    loadChildren: () => import('./pages/quote-add/quote-add.module').then( m => m.QuoteAddPageModule)
  },
  {
    path: 'orders/:timing',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'order/:id',
    loadChildren: () => import('./pages/order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'order-add/:id',
    loadChildren: () => import('./pages/order-add/order-add.module').then( m => m.OrderAddPageModule)
  },
  {
    path: 'information-requests',
    loadChildren: () => import('./pages/information-requests/information-requests.module').then( m => m.InformationRequestsPageModule)
  },
  {
    path: 'connect-auto-data',
    loadChildren: () => import('./pages/connect-auto-data/connect-auto-data.module').then( m => m.ConnectAutoDataPageModule)
  },
  {
    path: 'reviews/:id',
    loadChildren: () => import('./pages/reviews/reviews.module').then( m => m.ReviewsPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'rate-seller/:id',
    loadChildren: () => import('./pages/rate-seller/rate-seller.module').then( m => m.RateSellerPageModule)
  },
  {
    path: 'rate-buyer/:id',
    loadChildren: () => import('./pages/rate-buyer/rate-buyer.module').then( m => m.RateBuyerPageModule)
  },
  {
    path: 'review/:id',
    loadChildren: () => import('./pages/review/review.module').then( m => m.ReviewPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
