import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { SettingsService } from 'src/app/services/settings.service';
import { UpdateService } from 'src/app/services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  pages: Array<{title: string, staticpageurl: string, id: string, rating: string}>;
  
  constructor(
    private splashScreen: SplashScreen,
    public settings: SettingsService,
    private update: UpdateService
  ) {
    this.settings.log(1,'app.components: constructor');
    this.initializeApp();
    // used for an example of ngFor and navigation
    this.pages = [
      { title: 'Home', staticpageurl: '/home', id : '', rating: '1'},  
      { title: 'Login', staticpageurl: '/login', id : '', rating: '2'},   
    ];    
  }

  ngOnInit() {
    this.settings.log(1,'app.components: ngOnInit');
    //this.initializeApp();
  }

  initializeApp() {
    //this.splashScreen.show();
    let s = this.settings;
    s.log(1,'app.components: initializeApp start');
    s.router.navigateByUrl('/splash');
    //this.update.pageIsReady('appcomponent').then((ready) => {
    //s.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      //s.log(1,'initializeApp this.rootPage=' + this.rootPage);
      //s.getCoreModulesData(this.server).then(() => {
      s.log(1,'initializeApp page is ready s.appType=' + s.appType);
      //if (s.appType == 'public') {
      //  s.openPage('/home');
      //} else {
      //  s.openPage('/login');
      //}       
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
      // GR this.matomoInjector.init is calling trackPageView automatically - which we don't want because it results in crazy URLs
      // GR 20200702 - trying to use it again (was it changed?). Set 0 siteid here in order to replace it with real one later. 
      // That should hopefully avoid strange pageview event
      //this.matomoInjector.init(this.settings.matomoUrl, 0);
      //this.setupMenu();
      // do not use that method if multiple calls have negative effect (e.g. to start some heavy process)
      // it will be triggered multiple times!
      s.events.getObservable().subscribe((data) => {
        s.log(1,"appcomponent Updatefinished event. data.updatefinished=" + data.updatefinished);
        if (data.updatefinished) {
          data.updatefinished = false;
          this.setupMenu();
        }
      });	
      //}).catch((error)=>{
      //  s.log(2,"getCoreModulesData: error " + error.message);
      //});      
    //}).catch((error)=>{
    //  this.settings.log(1,"appcomponents pageIsReady error " + error.message);
    //  if (s.appType == 'public') {
    //    s.openPage('/home');
    //  } else {
    //    s.openPage('/login');
    //  }       
    //});
  }

  setupMenu() {
    let s = this.settings;
    s.log(1,'setupMenu s.userAuthenticated=' + s.userAuthenticated + ' s.currentUserRole=' + s.currentUserRole);
    this.pages = s.getCapMarketplaceMenuPages();
    /*
    s.getMenuPages().then((result) => {
      // reads current CurrentAppJson and finds pages marked to be displayed on main menu 
      s.log(1,'setupMenu getMenuPages() result.length=' + result.length);
      this.pages = result;
    }).catch((error)=>{
      s.log(2,'setupMenu error in getMenuPages: ' + error);
    });
    */
  }
}
